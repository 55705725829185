const Swal = require('sweetalert2');

const image = document.querySelector('#barcode img');
const link = document.querySelector('#barcode a');
const step2 = document.querySelector('#step2');
const address = document.querySelector('#address');
const coinspan = document.querySelector('#coin-span');
const coinamount = document.querySelector('#details .amount');
const confirmbtn = document.querySelector('#confirm');

// This forces Parcel to include all images and gives us a reference to their final asset path
const png = require('./images/*.png');
const svg = require('./images/*.svg');
const images = {...png, ...svg};

// For Google Charts API
const qr_width = 250;
const qr_whitespace = 0;

// Fetched config and fragment data
let config;
let amount;
let currency;
let title;

// This is a bit hackish as we re-gen a new Image on every click, we should cache them and hot-replace them.
function load_qr(element, text) {
    const chs = `${qr_width}x${qr_width}`;
    const chl = encodeURIComponent(text);
    const chld = `|${qr_whitespace}`;
    const url = `https://chart.googleapis.com/chart?chs=${chs}&cht=qr&chl=${chl}&choe=UTF-8&chld=${chld}`;
    const img = new Image();
    img.src = url;
    img.onload = () => element.setAttribute('src', url);
}

function invalid_page() {
    Swal.fire({
        icon: 'error',
        title: 'Oops!',
        text: 'This is not a valid payment link.',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        footer: '<a href="mailto:payments@martinvillalba.com">Send me a message</a>'
    });
}

function error_loading() {
    Swal.fire({
        icon: 'error',
        title: 'Oops!',
        text: 'There was an error loading the page. Please try again.',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        footer: '<a href="mailto:payments@martinvillalba.com">Send me a message</a>'
    });
}

function confirm_paid() {
    Swal.fire({
        icon: 'success',
        title: 'Thank you!',
        text: 'Thank you for you payment. You will receive confirmation by e-Mail once I\'ve checked the transaction.',
        footer: '<a href="mailto:payments@martinvillalba.com">Send me a message</a>'
    });
}

function get_fragment() {
    return window.location.hash ? window.location.hash.slice(1) : null;
}

function get_qs() {
    return new URLSearchParams(window.location.search).get('a');
}

function get_coin_amount(coin) {
    return (amount / config['quotes'][coin][currency]).toFixed(config['decimals'][coin]);
}

function coin_picked(e) {
    // Set QR placeholder image again
    image.setAttribute('src', images['barcode']);

    // Build QR link
    const coin = e.target.dataset.coin;
    const extra = config['extra'][coin] ? ` (${config['extra'][coin]})` : '';
    const scheme = config['schemes'][coin];
    const wallet = config['wallets'][coin];
    const coin_amount = get_coin_amount(coin);
    const title_encoded = encodeURIComponent(title);
    const href = `${scheme}:${wallet}?amount=${coin_amount}&label=${title_encoded}`;

    // Load QR code from Google Charts
    load_qr(image, href);

    // Setup step2 UI elements
    link.setAttribute('href', href);
    address.textContent = config['wallets'][coin];
    coinspan.textContent = coin;
    coinamount.textContent = `${coin_amount} ${coin}${extra}`;
    step2.classList.remove('hide');
}

function setup_payment_methods() {
    const ul = document.querySelector('.coins');
    ul.innerHTML = Object.keys(config['wallets']).map((k, i) => {
        const image = images[k.toLowerCase()];
        return `<li data-coin="${k}"><img data-coin="${k}" src="${image}" alt="${k}"></li>`;
    }).join('');
    ul.addEventListener('click', coin_picked);
}

function copy_clicked(e) {
}

async function setup_page() {
    // Set top-of-page info
    document.querySelector('.title').textContent = title;
    document.querySelector('.amount').textContent = `${amount} ${currency}`;

    // Pull config from backend
    const r = await fetch(`/api/config/${currency}`);
    if (!r.ok) error_loading();
    config = await r.json();

    // Place coin icons and buttons
    setup_payment_methods();

    // Wire I-have-paid button
    confirmbtn.addEventListener('click', confirm_paid);

    // // Wire copy icons
    // document.querySelectorAll('i.fa-copy').forEach(i => {
    //     const instance = new Tooltip(this, {
    //         title: "Copied",
    //         trigger: "click",
    //     });
    //     i.addEventListener('click', e => {
    //         console.log(e);
    //         copy_clicked(e);
    //         instance.show();
    //         setTimeout(() => instance.hide(), 500);
    //     });
    // });

    // Display payment steps
    document.querySelector('#steps-container').classList.remove('hide');
    document.querySelector('#loader').classList.add('hide');
}

function parse_fragment() {
    // Get args from fragment or query string (in case of redirection)
    let raw_args = get_fragment() || get_qs();

    // Display error modal if there is no usable fragment
    if (!raw_args) return invalid_page();

    // Unpack fragment
    [amount, currency, title] = JSON.parse(atob(raw_args));

    // Setup UI
    setup_page();
}

window.addEventListener('load', parse_fragment);
